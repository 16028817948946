import Vue from 'vue';
import Router from 'vue-router';
/*import LeituradeMapaQuiz from '../components/LeituradeMapaQuiz.vue';*/
/*Facebook*/ /*import LeituradeMapaVSL from '../components/LeituradeMapaVSL.vue';*/
import HelloWorld from '../components/HelloWorld.vue'
import PaginaPrincipal from '../components/PaginaPrincipal.vue'
//import UpsellVideo  from '../components/UpsellVideo.vue';
//import DownsellCarta from '@/components/DownsellCarta.vue';
import ObrigadoPage from '../components/ObrigadoPage.vue';
import KirvanoUpsellVideo from '../components/KirvanoUpsellVideo.vue'
//import DownsellCartaKirvano from '../components/DownsellCartaKirvano.vue'
import ObrigadoCorreio from '../components/ObrigadoCorreio.vue';
import ObrigadoAlmaGemea from '../components/ObrigadoAlmaGemea.vue';
/*import CartilhaNumerosSorte from '../components/CartilhaNumerosSorte.vue';
import NovaVersaoLp from '../components/NovaVersaoLp.vue';
import LpQuizz1Curto from '../components/LpQuizz1Curto.vue';
import PresellNovaVersaoLp from '../components/PresellNovaVersaoLp.vue'
import MahilaQuizz from '../components/MahilaQuizz.vue'
import MahilaTeste from '../components/MahilaTeste.vue'
import MahilaQuizzPS from '../components/MahilaQuizzPS.vue'
import MahilaTestePS from '../components/MahilaTestePS.vue'
import MahilaQuizzVturb from '../components/MahilaQuizzVturb.vue'
import MahilaTesteVturb from '../components/MahilaTesteVturb.vue'
import MahilaQuizzVturbTQuizz from '../components/MahilaQuizzVturbTQuizz.vue'
import MahilaTesteVturbTQuizz from '../components/MahilaTesteVturbTQuizz.vue'
import MahilaQuizzVturbPitchs from '../components/MahilaQuizzVturbPitchs.vue'
import MahilaTesteVturbPitchs from '../components/MahilaTesteVturbPitchs.vue'*/

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/enviodosdados',
      name: 'HelloWorld',
      component: HelloWorld,
      props: true
    },
    /*{
      path: '/664d453bck',
      name: 'LeituradeMapaQuiz',
      component: LeituradeMapaQuiz,
    },
    {
      path: '/leitura',
      name: 'LeituradeMapaVSL',
      component: LeituradeMapaVSL,
      props: true
    },*/
    /*Facebook*/
    {
      path: '/ZSDjpGw',
      name: 'PaginaPrincipal',
      component: PaginaPrincipal,
      props: true
    },    
    /*{
      path: '/664d453bc',
      name: 'UpsellVideo',
      component: UpsellVideo,
      props: false
    },/*Facebook*/
    {
      path: '/',
      name: 'KirvanoUpsellVideo',
      component: KirvanoUpsellVideo,
      props: false
    }, 
    /*{
      path: '/53bc664d4',
      name: 'DownsellCarta',
      component: DownsellCarta,
      props: false
    },
    {
      path: '/53bc664d4k',
      name: 'DownsellCartaKirvano',
      component: DownsellCartaKirvano,
      props: false
    },*/
    {
      path: '/typ',
      name: 'ObrigadoPage',
      component: ObrigadoPage,
      props: false
    },
    {
      path: '/obrigado-correio',
      name: 'ObrigadoCorreio',
      component: ObrigadoCorreio,
      props: false
    },
    {
      path: '/obrigado-almagemea',
      name: 'ObrigadoAlmaGemea',
      component: ObrigadoAlmaGemea,
      props: false
    },
    /*{
      path: '/cartilha',
      name: 'CartilhaNumerosSorte',
      component: CartilhaNumerosSorte,
      props: false
    },
    {
      path: '/Ieitura-23329xjk3wl',
      name: 'NovaVersaoLp',
      component: NovaVersaoLp,
      props: false,
      beforeEnter(to, from, next) {
        const script = document.createElement('script');
        script.src = '../js/video.js';
        script.async = true;
        document.head.appendChild(script);
        next();
      }
    },
    {
      path: '/Ieitura-41834128',
      name: 'LpQuizz1Curto',
      component: LpQuizz1Curto,
      props: false,
      beforeEnter(to, from, next) {
        const script = document.createElement('script');
        script.src = '../js/video.js';
        script.async = true;
        document.head.appendChild(script);
        next();
      }
    },
    {
      path: '/Ieitura-4128',
      name: 'PresellNovaVersaoLp',
      component: PresellNovaVersaoLp,
      props: false,
    },
    {
      path: '/Ieitura2321psquizz',
      name: 'MahilaQuizz',
      component: MahilaQuizz,
      props: true,
    },
    {
      path: '/leitura-hypk3',
      name: 'MahilaTeste',
      component: MahilaTeste,
      props: true,
    },
    {
      path: '/Ieitura2321psquizzps',
      name: 'MahilaQuizzPS',
      component: MahilaQuizzPS,
      props: false,
    },
    {
      path: '/leitura-hypk3ps',
      name: 'MahilaTestePS',
      component: MahilaTestePS,
      props: true,
    },
    {
      path: '/Ieitura2321psquizzv',
      name: 'MahilaQuizzVturb',
      component: MahilaQuizzVturb,
      props: true,
    },
    {
      path: '/leitura-hypk3v',
      name: 'MahilaTesteVturb',
      component: MahilaTesteVturb,
      props: true,
    },
    {
      path: '/Ieitura2321psquizzvc',
      name: 'MahilaQuizzVturbTQuizz',
      component: MahilaQuizzVturbTQuizz,
      props: true,
    },
    {
      path: '/leitura-hypk3vc',
      name: 'MahilaTesteVturbTQuizz',
      component: MahilaTesteVturbTQuizz,
      props: true,
    },
    {
      path: '/XC_CZoaJ-Rp_p2EtTQxECw',
      name: 'MahilaQuizzVturbPitchs',
      component: MahilaQuizzVturbPitchs,
      props: true,
    },
    {
      path: '/s9OfGPR4OWqKYajplShWJw',
      name: 'MahilaTesteVturbPitchs',
      component: MahilaTesteVturbPitchs,
      props: true,
    },*/
    

    
    /*TIKTOK
    {
      path: '/leitura',
      name: 'DownsellCarta',
      component: DownsellCarta,
      props: false
    },    
    {
      path: '/Ieitura',
      name: 'KirvanoUpsellVideo',
      component: KirvanoUpsellVideo,
      props: false
    },*/
    
],
});
